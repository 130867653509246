<template>
	<v-container v-if="activated">
		<v-card>
			<v-card-title>
				<v-toolbar-title>
					<span
						>{{ rowObject.brand_id }} -
						{{ rowObject.brand_name }}</span
					>
				</v-toolbar-title>
				<v-spacer />
				<v-toolbar-items>
					<v-btn icon @click.stop="$router.go(-1)"
						><v-icon>mdi-close-circle-outline</v-icon></v-btn
					>
				</v-toolbar-items>
			</v-card-title>
			<v-divider />
			<v-toolbar flat dense :color="bgColor">
				<v-toolbar-items>
					<v-btn
						icon
						color="primary"
						:disabled="updateActive"
						v-on:click.stop="beginUpdate()"
						><v-icon>mdi-pencil-outline</v-icon>
					</v-btn>
					<v-btn
						icon
						:disabled="!dataModified"
						v-on:click.stop="saveChanges()"
						color="green"
						><v-icon>mdi-check-outline</v-icon></v-btn
					>
					<v-btn
						icon
						color="red"
						:disabled="!updateActive"
						@click.stop="cancelUpdate()"
						><v-icon>mdi-close</v-icon></v-btn
					>
					<v-btn
						icon
						:disabled="updateActive"
						@click.stop="openDialogDeleteBrand()"
						><v-icon>mdi-delete-outline</v-icon></v-btn
					>
				</v-toolbar-items>
			</v-toolbar>
			<v-divider />
			<v-card-text>
				<v-form :readonly="!updateActive">
					<v-row>
						<v-col cols="9">
							<v-text-field
								label="Naam"
								v-model="rowObject.brand_name"
								hide-details="auto"
								@input="handleInput"
							/>
						</v-col>
						<v-col cols="3" align="right">
							<v-checkbox
								label="Actief"
								v-model="rowObject.is_active"
								hide-details="auto"
								@change="handleInput"
							/>
						</v-col>
						<v-col cols="9">
							<vue-editor
								v-model="rowObject.brand_descr_long"
								v-on:text-change="handleInput"
								:editorToolbar="customToolbar"
								:disabled="!updateActive"
							></vue-editor>
						</v-col>
						<!-- IMAGE -->
						<v-col cols="3">
							<v-card
								width="150"
								outlined
								v-if="Number(rowObject.img_id) > 0"
								flat
							>
								<v-card-title>
									<v-img
										:src="rowObject.img_tn_url"
										aspect-ratio="1"
										max-width="100%"
										contain
										position="center center"
									/>
								</v-card-title>
								<v-card-actions>
									<v-btn
										:disabled="!updateActive"
										icon
										plain
										@click="detachImage"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn></v-card-actions
								>
							</v-card>
							<v-card v-else flat :loading="loading">
								<v-file-input
									:loading="loading"
									:disabled="!updateActive"
									v-model="file"
									prepend-icon="mdi-camera"
									flat
									hide-details
									hide-input
									@change="uploadFile()"
								/>
							</v-card>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
		</v-card>
		<v-dialog v-model="dialogDeleteBrand" width="auto">
			<v-card>
				<v-card-title class="text-subtitle-1">
					{{ currentRowObject.brand_name }} &nbsp;
					<span>: verwijderen? </span>
				</v-card-title>
				<v-divider />
				<v-card-actions>
					<v-spacer />
					<v-btn text color="primary" @click="deleteBrand()"
						>Verwijderen</v-btn
					>
					<v-btn text plain @click="dialogDeleteBrand = false"
						>Annuleren</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
	import caas from "@/services/caas";
	import { mapGetters } from "vuex";
	import { VueEditor } from "vue2-editor";
	export default {
		name: "Brand",
		components: { VueEditor },
		data() {
			return {
				dialogDeleteBrand: false,
				activated: false,
				loading: false,
				file: {},
				customToolbar: [
					["bold", "italic", "underline"],
					[{ list: "ordered" }, { list: "bullet" }],
					["link"],
				],
				rowObject: {},
				currentRowObject: {},
				currentRowObjectBefore: {},
				updateActive: false,
				dataModified: false,
			};
		},
		activated() {
			this.fetchBrand(this.$router.currentRoute.params.id);
		},
		computed: {
			...mapGetters({
				bgColor: "session/bgColor",
			}),
		},
		methods: {
			openDialogDeleteBrand() {
				this.currentRowObject = this.rowObject;
				this.dialogDeleteBrand = true;
			},
			deleteBrand() {
				let sdoName = "web/objects/dECatBrand.r",
					params = {
						action: "delete",
						rowObjUpd: [],
					};
				this.currentRowObject.RowMod = "D";
				params.rowObjUpd.push(this.currentRowObject);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.$store.dispatch("session/showSuccess");
							this.currentRowObject = {};
							this.dialogDeleteBrand = false;
							this.$router.go(-1);
						} else if (result.data.error) throw result.data.error;
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
			uploadFile() {
				if (!this.file) return;
				const that = this;
				const fileReader = new FileReader();
				fileReader.onload = function () {
					let params = {};
					params.itemId = 0;
					params.itemSku = "";
					params.fileName = that.file.name;
					params.b64 = fileReader.result.replace(/^data:.+;base64,/, "");
					caas.rpc("createImage", params)
						.then((response) => {
							if (response.data.success) {
								that.rowObject.img_id =
									response.data.success.imageId;
								that.rowObject.img_tn_url =
									response.data.success.imageThumbUrl;
								that.dataModified = true;
							} else if (response.data.error) {
								that.$store.dispatch(
									"session/showBottomMessage",
									response.data.error
								);
							}
						})
						.finally(() => {
							that.file = null;
							that.loading = false;
						});
				};
				this.loading = true;
				fileReader.readAsDataURL(this.file);
			},
			detachImage() {
				this.dataModified = true;
				this.rowObject.img_id = 0;
			},
			fetchBrand(brand_id) {
				this.loading = true;
				const sdoName = "web/objects/dECatBrand.r";
				const params = {
					action: "read",
					nbrOfRows: 1,
					setOpenQuery:
						"for each ecat_brand where brand_id=" +
						brand_id +
						" no-lock indexed-reposition",
				};
				caas.sdo(sdoName, params)
					.then((response) => {
						if (response.data.success) {
							this.rowObject =
								response.data.success.data.RowObject[0];
						}
					})
					.finally(() => {
						this.loading = false;
						this.activated = true;
					});
			},
			handleInput() {
				this.dataModified = true;
			},
			beginUpdate() {
				this.updateActive = true;
				Object.assign(this.currentRowObjectBefore, this.rowObject);
			},
			cancelUpdate() {
				this.updateActive = false;
				this.dataModified = false;
				Object.assign(this.rowObject, this.currentRowObjectBefore);
			},
			saveChanges() {
				// server update
				let sdoName = "web/objects/dECatBrand.r",
					params = {
						action: "update",
						rowObjUpd: [],
					};
				this.rowObject.RowMod = "U";
				params.rowObjUpd.push(this.rowObject);
				params.rowObjUpd.push(this.currentRowObjectBefore);
				this.loading = true;
				caas.sdo(sdoName, params)
					.then((result) => {
						if (result.data.success) {
							this.$store.dispatch("session/showSuccess");
							this.updateActive = false;
							this.dataModified = false;
							this.currentRowObjectBefore = {};
							this.rowObject.RowMod = "";
						}
						if (result.data.error) {
							throw result.data.error;
						}
					})
					.catch((error) => {
						this.$store.dispatch("session/showBottomMessage", error);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
